import React from "react"
import { Navbar } from "reactstrap"
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaGithubSquare,
} from "react-icons/fa"
import ccImg from "../../images/cc.png"

const Footer = () => {
  return (
    <footer>
      <Navbar color="dark" dark expand="md">
        <div className="col-lg-12 text-center">
          <p className="mt-3">
            © {new Date().getFullYear()} by Ponto G++. Gerado com
            <a
              href="https://www.gatsbyjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Gatsby
            </a>
            <a
              target="_blank"
              rel="license noopener noreferrer"
              href="http://creativecommons.org/licenses/by-nc/4.0/"
            >
              <img src={ccImg} alt="Creative Commons Logo" />
            </a>
            <a
              href="https://www.facebook.com/josea.gaeta.mendes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookSquare />
            </a>
            <a
              href="https://twitter.com/GaetaJa"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitterSquare />
            </a>
            <a
              href="https://github.com/ja-gaeta"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithubSquare />
            </a>
          </p>
        </div>
      </Navbar>
    </footer>
  )
}

export default Footer
