import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "gatsby"
import { FaPenSquare, FaBook, FaMotorcycle } from "react-icons/fa"
import logo from "../../images/logo.svg"

const NavMenu = props => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Navbar color="dark" dark expand="md" className="fixed-top">
        <div className="container">
          <NavbarBrand href="/">
            <img style={{ width: "70%" }} src={logo} alt="Logotipo do site" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">Início</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/tags">Tópicos</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Blog
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link to={"/tecnicos/pagina/1"} className="submenu">
                      <FaBook /> Artigos Técnicos
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/cronicas/pagina/1"} className="submenu">
                      <FaPenSquare /> Crônicas & Ensaios
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Vídeos
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link to={"/cursos"}>
                      <FaBook /> Cursos & Tutoriais
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={"/vovotoqueiro"}>
                      <FaMotorcycle /> Vovotoqueiro
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/about">Sobre</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default NavMenu
