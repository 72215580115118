import PropTypes from "prop-types"
import React from "react"
import NavMenu from "../layout/navbar"

const Header = () => (
  <header>
    <NavMenu />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
